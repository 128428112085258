Vue.component('loginComponent',{
    props:['register'],
    data:function(){
        return {
            message:null,
            username:'',
            password:'',
            beforeLogin:false
        }
    },
    watch:{
        "$store.getters.getIsLogin":'closeModal',
        "username":function(){this.message=''},
        "password":function(){this.message=''},
        "beforeLogin":function(){this.message=''},
    },
    methods:{
        clear(){
            this.message='';
            this.username='';
            this.password='';
            this.beforeLogin = false;
        },
        async login(){
            this.beforeLogin=true;
            let loginResult = await this.$store.dispatch('login',{username:this.username,password:this.password});
            //console.log('Login Result',loginResult);
            if(loginResult.ok==false)
                this.message = this.tr("Incorrect Username or Password");
            if (this.$store.getters.getIsLogin) {
                this.clear();
                $('#loginModal').modal('close');
                if(loginResult.mustUpdateInfo==true)
                    this.$router.push('/mustUpdateInfo');
            }
            else
                this.message = this.tr("Incorrect Username or Password");

        },
        closeModal:function(){
            //console.log('check is close modal',this.$store.getters.getIsLogin);
            if(this.$store.getters.getIsLogin) {
                this.clear();
                $('#loginModal').modal('close');
            }
            else {
                this.message = this.tr("Incorrect Username or Password");
            }
        }
    },
    mounted:function(){
        $(this.$refs['loginModal']).modal({
            dismissible: true,
            complete : this.clear,
        });
    },
    template:`<div ref="loginModal" id="loginModal" class="modal">
                  <div class="modal-content">
                      <h5 class="center-align">{{tr('Login')}}</h5>
                      <div class="col s6 offset-s3 center-align">
                            <div class="input-field">
                                <input :placeholder="tr('User')" v-model="username" name="username" type="text" class="validate">
                            </div>
                            <div class="input-field">
                                <input :placeholder="tr('Password')" v-model="password"  id="password" name="password" type="password" class="validate">
                            </div>
                            <p class="input-field">
                                <input name="redirect_on_success" class="input" type="hidden" value="/ecommerce/#/">
                            </p>
                            <div :class="{'center-align':true,'hide':!beforeLogin ,'red':true,'white-text':true}">
                                <p>{{message}}</p>
                            </div>
                            <p class="control center-align">
                                <template v-if="message">
                                    <button class="center-align btn waves-effect waves-light" @click.prevent="login">{{tr('Try Again')}}</button>
                                    <button class="modal-action modal-close center-align btn waves-effect waves-light" >{{tr('Close')}}</button>
                                </template>
                                <template v-else="message">
                                    <button class="center-align btn waves-effect waves-light" @click.prevent="login">{{tr('Login')}}</button>
                                    <button class="modal-action modal-close center-align btn waves-effect waves-light">{{tr('Close')}}</button>
                                </template>
                            </p>
                      </div>
                  </div>
               </div>`
});